import { IonApp, IonHeader, IonSplitPane, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Theme variables */
import "./theme/variables.css";
/* Estructuras de layout */
import "./theme/layouts.css";
import "rsuite/dist/rsuite.min.css";

import { CustomProvider } from "rsuite";
import { JSPrintManager, WSStatus } from "jsprintmanager";
import {
  AutoPrintDaemon,
  GetCategoryPrinters,
  PrintOrderProduct,
} from "./services";
import {
  P,
  ReadParameter,
  GetTerminalIp,
  SetTerminalIp,
  GetSuppressAutoprint,
} from "./business";
import { ORDER_TYPES } from "./constants";
import {
  printMessages,
  ExternalDaemon,
  printerDaemon,
} from "./pages/Print/PrintController";
import { Suspense, lazy, useEffect, useState } from "react";
import { PrintMesssages } from "./services/messages/MessageServices";
import { get, onValue } from "@firebase/database";
import { dbRef } from "./firebase";

import Routes from "./navigation/Routes";
setupIonicReact();

const SidebarMenu = lazy(() => import("./components/SidebarMenu"));
const HeaderBar = lazy(() => import("./components/HeaderBar"));

const RunPrintingDaemon = () => {
  AutoPrintDaemon().then(
    ({ response, messages, print_delivery, print_takeaway }) => {
      if (response.length > 0 || messages.length > 0) {
        let orders = response;

        if (print_delivery == 1 && print_takeaway == 0)
          orders = orders.filter((o) =>
            [ORDER_TYPES.DELIVERY, ORDER_TYPES.LOCAL].includes(o.type)
          );
        else if (print_takeaway == 1 && print_delivery == 0)
          orders = orders.filter((o) =>
            [ORDER_TYPES.DELIVERY, ORDER_TYPES.LOCAL].includes(o.type)
          );
        else if (print_takeaway == 0 && print_delivery == 0)
          orders = orders.filter((o) => o.type == ORDER_TYPES.LOCAL);

        const products_in_orders = orders.flatMap((r) => r.products);
        const categories = Array.from(
          new Set(products_in_orders.map((item) => item.category_id))
        );
        GetCategoryPrinters(categories).then(async (printersResponse) => {
          let date = new Date();

          if (printersResponse.result_code !== 200) return;

          if (JSPrintManager.websocket_status == WSStatus.Open) {
            if (orders.length > 0) {
              printerDaemon(
                date.toLocaleDateString(),
                date.toLocaleTimeString(),
                orders,
                printersResponse.response.printers,
                0,
                0,
                0
              );
              PrintOrderProduct(
                orders.flatMap((o) => o.opids_to_print).map((p) => p.id)
              );
            }

            if (
              messages.length > 0 &&
              printersResponse.response.communication_printers.length > 0
            )
              printMessages(
                messages,
                printersResponse.response.communication_printers,
                0,
                0
              ).then(() => PrintMesssages(messages.map((m) => m.id)));
          }

          const customOrders = response.filter(
            (o) =>
              o.type == ORDER_TYPES.DELIVERY || o.type == ORDER_TYPES.TAKEAWAY
          );

          if (customOrders.length > 0) {
            if (JSPrintManager.websocket_status == WSStatus.Open) {
              const customOrders = response.filter(
                (o) => o.type != ORDER_TYPES.LOCAL
              );
              ExternalDaemon(
                date.toLocaleDateString(),
                date.toLocaleTimeString(),
                customOrders,
                printersResponse.response.delivery_printers,
                printersResponse.response.takeaway_printers,
                0,
                0
              );
              PrintOrderProduct(
                customOrders.flatMap((o) => o.opids_to_print).map((p) => p.id)
              );
            }
          }
        });
      }
    }
  );
};

let T = 1;
let LAST_V = "";

const App: React.FC = () => {
  const [daemon, setDaemon] = useState<NodeJS.Timeout>();

  // Start WS for JSPM
  JSPrintManager.auto_reconnect = true;
  JSPrintManager.license_url =
    "https://www.neodynamic.com/licenses/jspm/v5/fooddynamic";

  // Connect to terminal or to local JSPM Client
  if (
    JSPrintManager.websocket_status != WSStatus.Open &&
    GetSuppressAutoprint().value != "true"
  )
    if (
      !GetTerminalIp().empty
      //  &&
      // /(Mobi|Android|iPhone|iPad|Windows Phone|webOS|BlackBerry|Opera Mini|IEMobile|Tablet)/i.test(
      //   navigator.userAgent
      // )
    ) {
      JSPrintManager.start(true, GetTerminalIp().value, 25443);
      SetTerminalIp(GetTerminalIp().value);
    } else JSPrintManager.start();

  function DaemonDispatcher() {
    if (ReadParameter(P.enable_autoprint) !== "true") return;
    if (!GetTerminalIp().empty) return;
    if (JSPrintManager.websocket_status !== WSStatus.Open) return;
    if (GetSuppressAutoprint().value === "true") return;

    if (T === 6) {
      RunPrintingDaemon();
      T = 0;
    } else
      get(dbRef).then((snp) => {
        if (snp.exists()) {
          if (snp.val().lastOrder !== LAST_V) {
            LAST_V = snp.val().lastOrder;
            RunPrintingDaemon();
          }
        } else console.warn("No FRDB value was found");
        T += 1;
      });
  }

  useEffect(() => {
    if (!daemon) {
      clearInterval(daemon);
      setDaemon(setInterval(DaemonDispatcher, 5_000));
    }

    onValue(dbRef, (snapshot) => {
      T = 6;
    });
  }, []);

  return (
    <CustomProvider theme="light">
      <IonApp>
        <IonReactRouter>
          <IonHeader>
            <Suspense
              fallback={<div style={{ height: 56, background: "#052B3A" }} />}
            >
              <HeaderBar />
            </Suspense>
          </IonHeader>
          <IonSplitPane contentId="main">
            <Suspense fallback={<></>}>
              <SidebarMenu />
            </Suspense>
            <Routes />
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    </CustomProvider>
  );
};

export default App;
